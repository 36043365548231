import React from 'react'
import styled from "styled-components";
import FadeMeIn from './FadeMinIn'
import Glow from './Glow'

const QuizButton = styled.div`
  font-size: 100%;
  font-weight: 300;
  z-index: 1;
  padding: 8px;
  border-width:1px;
  border-style: solid;
  border-radius: 4px;


:hover {
    transition: 0.6s;
    background-color: #ffe18e;
    color: #421710;
}
`
const MenuDiv = styled.div`
display: flex;
align-items: center;
flex-direction: column;
padding-top: 15px;
padding-bottom: 15px;
text-align: center;
a {
    font-size: 80%;
    color: #ffe18e;
    text-decoration-line: none;
}
a:hover {
    text-decoration-line: underline;
    font-size: 100%;
}
`
const LineSpan = styled.span`
    white-space: pre;
    text-decoration-line: line-through

`


const MenuItem = ({ item, setQuizUrl }) => {
    return <>
        <QuizButton onClick={() => setQuizUrl(item.url)}>{item.title}</QuizButton>
        <p style={{ marginTop: 0 }}><em>{item.text}</em></p>
    </>
}


const MenuSection = ({ section, setQuizUrl }) => {
    return <MenuDiv>
        <h3><LineSpan>     </LineSpan>{section.title}<LineSpan>     </LineSpan></h3>
        {section.glitzzes.map(g => <MenuItem key={g.url} item={g} setQuizUrl={setQuizUrl} />)}
    </MenuDiv>
}

const Menu = ({ menu, setQuizUrl }) => {
    if (!menu) {
        return <div />
    }
    return (
        <MenuDiv>
            <FadeMeIn delay={1000} duration={2000} initialDelay={3000}>
                <div style={{ paddingBottom: "30px" }}>Learn with <Glow>Quiz</Glow>, get some <Glow>Glitz</Glow></div>
                {menu.sections.map(s => <MenuSection key={s.title} section={s} setQuizUrl={setQuizUrl} />)}
                <div>
                    <a href={"https://github.com/claudevervoort/glitzquizzes"} target={"_blank"} rel={"noreferrer"}>or create your own glitzquiz...</a>
                </div>
            </FadeMeIn>
        </MenuDiv>
    )
}

export default Menu
