import { React, useState, useEffect } from 'react'
import FancyTitle from './components/FancyTitle';
import GlitzQuizTitle from './components/GlitzQuizTitle'
import FadeMeIn from './components/FadeMinIn'
import AnimatedButton from './components/AnimatedButton'
import Glow from './components/Glow'
import styled from "styled-components";
import { loadMenu, loadQuiz } from './services/quizloader'
import { StarryBackground } from './components/StarryBackground'
import { Track, soundtrack } from './services/soundfx'
import SoundControl from './components/SoundControl'
import HomeButton from './components/HomeButton'
import StarLoading from './components/StarLoading'
import Footer from './components/Footer'
import Menu from './components/Menu'
import ReactGA from 'react-ga4'
ReactGA.initialize('G-RL6LBX2C6J');

const Wrapper = styled.div`
display: flex;
justify-content: center;
width:100%;
height:100%;
font-family: 'Roboto', sans-serif;
color: #ffe18e;
z-index: 1;
`
const ButtonPanel = styled.div`
    position: absolute;
    z-index: 10;
    svg {
        height: 25px;
    }
`

const InfoPopup = styled.div`
    border-radius: 6px; 
    z-index: 10;
    position: absolute;
    top: 80px;
    padding: 10px;
    width: 500px;
    background-color: #ffe18e;
    color: #421710;
`

const Instruction = styled.div`
padding-top: 20px;
font-size: 150%;
color: #dfe18e;
font-family: 'Yanone Kaffeesatz', sans-serif;
`

const Panel = styled.div`
display: flex;
align-items: center;
flex-direction: column;
@media(orientation: landscape) {
  margin-top: 10px;
  width: 40%;
}
@media(orientation: portrait) {
  width: 90%;
}
`

const Cover = styled.div`
display: flex;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`

document.body.style = 'background: #421710;';

function App() {
  const [displayQuiz, setDisplayQuiz] = useState(false)
  const [menu, setMenu] = useState(null)
  const urlParams = new URLSearchParams(window.location.search)
  const [quizUrl, setQuizUrl] = useState(urlParams.get('url'))
  const [reportUrl] = useState(urlParams.get('scorereporturl'))
  const [showInfo, setShowInfo] = useState(false)
  const [quiz, setQuiz] = useState(null)
  const [failedLoading, setFailedLoading] = useState(false)
  const showHome = () => {
    setQuiz(null)
    setQuizUrl(null)
    setDisplayQuiz(false)
    setFailedLoading(false)
  }
  soundtrack(Track.NotTaking)
  useEffect(() => {
    loadMenu().then(menu => setMenu(menu))
  }, [])
  useEffect(() => {
    setShowInfo(false)
    if (quizUrl) {
      //window.setTimeout(() => setFailedLoading(true), 4000)
      loadQuiz(quizUrl, reportUrl).then(setQuiz).catch(() => {
        setFailedLoading(true)
      })
    }
  }, [quizUrl, reportUrl])
  return <StarryBackground><Wrapper>
    <Panel>
      <ButtonPanel><SoundControl />{(quiz || displayQuiz) && <HomeButton onClick={showHome} />}{!displayQuiz && <span style={{ fonSize: '120%', verticalAlign: 'top', padding: '5px' }} onClick={() => setShowInfo(!showInfo)}>?</span>}</ButtonPanel>
      {showInfo && <InfoPopup onClick={() => setShowInfo(false)}><p>Glitch not Glitz? Send me a message and thanks for the feedback!</p>
        <p>Glitzquiz is a 100% front end webapp that grew from the need to do a simple but fun quiz on LTI, an ed-tech specification.
          The content is decoupled from the actual gitzquiz application, so it can play any content in the glitzquiz format,
          which is just markdown! If you find any glitches or want to give some feedback on this app, feel free to drop a note
          <a href="mailto:zeedeeyou+glitzquiz@gmail.com">zeedeeyou#glitzquiz@gmail.com</a>. Thanks for playing!</p>
      </InfoPopup>}
      {!displayQuiz && <Cover>
        <GlitzQuizTitle />
        {!quizUrl && <>
          <Menu menu={menu} setQuizUrl={setQuizUrl} />
        </>}
        {quizUrl && !quiz && <StarLoading broken={failedLoading} />}
        {quiz && <><FancyTitle title={quiz.title} initialDelay={0} />
          <FadeMeIn delay={1000} duration={6000} initialDelay={1000}>
            <Instruction>
              You start with <Glow>10000</Glow> Glitz
            </Instruction>
            <Instruction>
              Each good answer gives you more Glitz...
            </Instruction>
            <Instruction>
              ...but each bad answer costs you some Glitz
            </Instruction>
            <Instruction>And everyone knows...</Instruction>
            <Instruction><b>Time is Glitz</b></Instruction>
            <AnimatedButton label="Let's Glitz!" onClick={() => setDisplayQuiz(true)} style={{ display: 'block' }} />
          </FadeMeIn></>}
      </Cover>}
      {displayQuiz && quiz.quiz}
      <Footer />
    </Panel>
  </Wrapper>
  </StarryBackground>
}

export default App;
