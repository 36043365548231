import { React, useLayoutEffect, createRef } from 'react'
import anime from 'animejs'

export default function FadeMinIn({ children, delay, initialDelay, duration }) {
    console.log(children)
    const refs = []
    const wrapChildren = (kids) => kids.map((c, i) => {
        const ref = createRef()
        refs.push(ref)
        if (c.children) c.children = wrapChildren(c.chidlren)
        return <div style={{ opacity: 0 }} ref={ref} key={i}>{c}</div>
    })
    const wrappedChildren = wrapChildren(children)
    useLayoutEffect(() => {
        for (let index = 0; index < children.length; index++)
            anime({
                targets: refs[index].current,
                opacity: 1,
                duration,
                delay: initialDelay + index * delay,
            })
    })
    return (
        <>
            {wrappedChildren}
        </>
    )
}
