import { React, useLayoutEffect, createRef } from 'react'
import styled from 'styled-components'
import anime from 'animejs'

const FSpan = styled.div`
color: #ffe18e;
position: relative;
//font-family: 'Roboto', sans-serif;
font-family: 'Bebas Neue', cursive;
font-size: 200%;
opacity: 0;
display: inline-block;
`
function FancyChar({ char, index, initialDelay }) {
    const ref = createRef()
    useLayoutEffect(() => {
        if (ref.current) {
            anime({
                targets: [ref.current],
                translateY: 0,
                opacity: 1,
                duration: 500,
                delay: initialDelay + index * 60,
            })
        }
    }, [ref, index, initialDelay])

    return (
        <FSpan style={{ transform: 'translateY(-30px)' }} ref={ref}>
            {char.trim() || '\u00A0'}
        </FSpan>
    )
}


export default function FancyTitle({ title, initialDelay }) {
    const chars = []
    for (let i = 0; i < title.length; ++i) {
        chars.push({ char: title.charAt(i), index: i, initialDelay })
    }
    return (
        <div>
            {chars.map((c, i) => (<FancyChar key={i} {...c} />))}
        </div>
    )
}
