import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'

import rehypeReact from 'rehype-react'
import { unified } from 'unified'
import GlitzQuiz from '../components/GlitzQuiz'
import React from 'react'

const toReactMarkdown = (n) => unified()
    .use(function mockParser() {
        Object.assign(this, { Parser: () => n })
    })
    .use(remarkRehype)
    .use(rehypeReact, { createElement: React.createElement })

const extractText = (c) => c.reduce((t, tc) => t + (tc.value || ""), "")

//const flat = (n) => n.children?n.children.flatMap(n => n.children ? n.children.reduce((r, n) => [...r, ...flat(n)], [n]) : [n])
const flat = (n, t) => (n.type === t) ? [] : n.children ? n.children.reduce((a, c) => [...a, ...flat(c, t)], [n]) : [n]

const toMenu = (url, text, onSuccess, onFailure) => {
    const menuExtractor = function () {
        const compiler = (node) => {
            window.zeNode = node;
            let inMenu = false;
            let menu = {
                sections: []
            };
            let section;
            node.children.forEach(function (n) {
                console.log('node', n)
                if (n.type === "heading" && n.depth === 2 && n.children) {
                    inMenu = extractText(n.children).toLowerCase().indexOf('official') !== -1
                } else if (inMenu) {
                    if (n.type === "heading" && n.depth === 3) {
                        section = {
                            title: extractText(n.children),
                            glitzzes: []
                        }
                        menu.sections.push(section)
                    } else if (n.type === 'list') {
                        section.glitzzes = n.children.map(li => {
                            let glitz = {
                                url: "",
                                title: "",
                                text: ""
                            }
                            flat(li).forEach(it => {
                                if (it.type === 'link') {
                                    glitz.url = it.url
                                    glitz.title = extractText(it.children)
                                }
                            })
                            console.log(flat(li, 'link'))
                            glitz.text = extractText(flat(li, 'link'))
                            return glitz;
                        })
                    }
                }
            })
            return menu
        }
        Object.assign(this, { Compiler: compiler })
    }
    unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(menuExtractor)
        .process(text)
        .then(f => { onSuccess(f.result) })
        .catch(f => onFailure(f))
}

const toQuiz = (reportUrl) => (url, text, onSuccess, onFailure) => {
    const qq = function () {
        const compiler = (node) => {
            window.zeNode = node;
            let title = ""
            let questions = []
            let question;
            node.children.forEach(function (n) {
                if (n.type === "heading" && n.depth === 1) {
                    if (!title) {
                        title = n.children.reduce((t, tc) => t + (tc.value || ""), "")
                    }
                } else if (n.type === "heading" && n.depth === 2) {
                    question = {
                        text: [],
                        feedback: [],
                    }
                } else if (n.type === "paragraph" || n.type === "code") {
                    const text = toReactMarkdown(n).processSync("").result;
                    if (question) {
                        if (question.choices) {
                            question.feedback.push(text)
                        } else {
                            question.text.push(text)
                        }
                    }
                } else if (n.type === 'list' && question && !question.choices) {
                    const choices = n.children.filter(c => c.checked !== null)
                        .map(li => {
                            return {
                                content: toReactMarkdown(li.children[0]).processSync("").result,
                                correct: li.checked
                            }
                        })
                    if (choices.length > 0) {
                        question.choices = choices
                        questions.push(question)
                    }
                } else {
                    console.log('ignored', n)
                }
            })
            if (!title || !questions) {
                throw new Error("No quiz data found")
            }
            return {
                title,
                quiz: <GlitzQuiz questions={questions} title={title} url={url} reportUrl={reportUrl} />
            }
        }
        Object.assign(this, { Compiler: compiler })
    }
    unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(qq)
        .process(text)
        .then(f => { onSuccess(f.result) })
        .catch(f => onFailure(f))
    /** 
remark()
    .use(toQuizTransformer)
    .use(qq)
    .process(text)
    .then(f => console.log(f))
    */
}

const load = (url, transform) => {
    return new Promise((successCallback, failureCallback) => {
        fetch(url).then((r) => {
            if (!r.ok) {
                failureCallback(r)
            } else {
                r.text().then(text => {
                    try {
                        transform(url, text, successCallback, failureCallback)
                    } catch (error) {
                        failureCallback();
                    }
                })
            }
        }, failureCallback);
    })
}

export const loadMenu = () => { return load('README.md', toMenu) }
export const loadQuiz = (url, reportUrl) => { return load(url, toQuiz(reportUrl)) }