import React from 'react'
import styled from 'styled-components'

const FooterDiv = styled.div`
    font-size:  80%;
    a {
        color: #ffe18e;
        text-decoration-line: none;
    }

a:hover {
    transition: 0.6s;
    background-color: #ffe18e;
    color: #421710;
}

`

export default function Footer() {
    return (
        <FooterDiv>
            <a href={"https://github.com/claudevervoort/glitzquizzes"} target={"_blank"} rel={"noreferrer"}>Glitzquizzes Repo</a> |
            <a href={"https://discord.gg/ZXDM6XpnXU"} target={"_blank"} rel={"noreferrer"}>Discord</a>
        </FooterDiv>
    )
}
