import { React, useLayoutEffect, createRef, useRef, memo } from 'react'
import styled from 'styled-components'
import anime from 'animejs'
import { ReactComponent as Z } from './titleletters/glitzquiz-z.svg'
import { ReactComponent as CenterZ } from './titleletters/glitzquiz-UpperZ.svg'
import { ReactComponent as L } from './titleletters/glitzquiz-l.svg'
import { ReactComponent as Q } from './titleletters/glitzquiz-q.svg'
import { ReactComponent as T } from './titleletters/glitzquiz-t.svg'
import { ReactComponent as U } from './titleletters/glitzquiz-u.svg'
import { ReactComponent as G } from './titleletters/glitzquiz-g.svg'
import { ReactComponent as I } from './titleletters/glitzquiz-i.svg'
import { ReactComponent as Star } from './titleletters/star.svg'

const TitleBox = styled.div`
    position: relative;
    height:100px;
    padding-top: 40px;
    padding-bottom: 15px;
`

const Title = styled.div`
    padding-top: 60px;
    svg {
        position: relative;
        height: 40px;
    }
    svg path {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:4px;
    }
`
const FloatingStar = styled.div`
    position: absolute;
    svg {
        height: 30px;
    }
    polygon {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:4px;
    }
`

const GlitzQuizTitle = () => {
    const refZ = createRef()
    const refI = createRef()
    const refStar = createRef()
    const refCont = createRef()
    const animStartedRef = useRef(false)
    const RotatingZ = styled(CenterZ)`
        position: relative;
        z-index: 10;
    `
    useLayoutEffect(() => {
        if (!animStartedRef.current) {
            let starEl = refStar.current;
            let iEl = refI.current;
            window.iEl = iEl;
            window.starEl = starEl;
            let starX = Math.floor(iEl.getBoundingClientRect().x - refCont.current.getBoundingClientRect().x)
            let starY = Math.floor(iEl.getBoundingClientRect().y - refCont.current.getBoundingClientRect().y) - 60
            starEl.style.left = starX + "px"
            starEl.style.top = starY + "px"
            anime.timeline()
                .add({
                    targets: [refZ.current],
                    rotateZ: [10, 390],
                    duration: 2000,
                    easing: 'easeOutBounce',
                    scale: [1.4, 1.4]
                })
                .add({
                    targets: '.glitzquiz-title svg path.progstroke, polygon.progstroke',
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutSine',
                    duration: 1000,
                    delay: function (el, i) { return i * 250 }
                }, "-=1500")
                .add({
                    targets: starEl,
                    translateY: 35,
                    duration: 400,
                    easing: 'easeInSine',
                })
                .add({
                    targets: starEl,
                    translateY: 25,
                    easing: 'easeOutBounce',
                    duration: 500,
                })
                .add({
                    targets: iEl.getElementsByTagName('path')[0],
                    d: [
                        { value: iEl.getElementsByTagName('path')[0].dataset.squeezed },
                        { value: iEl.getElementsByTagName('path')[0].dataset.squeezed_up },
                        { value: iEl.getElementsByTagName('path')[0].getAttribute('d') }
                    ],
                    duration: 500,
                    loop: false
                }, '-=500');
            animStartedRef.current = true;
        }
    }, [refCont, refI, refStar, refZ, animStartedRef])

    return <TitleBox ref={refCont} className="glitzquiz-title">
        <Title ><G /><L /><I /><T /><RotatingZ ref={refZ} /><Q /><U /><I ref={refI} /><Z /></Title>
        <FloatingStar ref={refStar} ><Star /></FloatingStar></TitleBox>
}

export default memo(GlitzQuizTitle)