import React, { useState, useRef, useEffect, memo } from 'react'
import AnimatedButton from './AnimatedButton'
import Choice from './Choice'
import { Track, soundtrack } from '../services/soundfx'
import styled from "styled-components";


const QuestionText = styled.div`
font-size: 150%;
padding-right: 10px;
padding-left: 10px;
text-align: center;
font-family: 'Yanone Kaffeesatz', sans-serif;
padding-bottom: 20px;
`

const FeedbackPanel = styled.div`
background-color: #ffe18e;
font-size: 150%;
padding-right: 10px;
padding-left: 10px;
color: #421710;
text-align: center;
font-family: 'Yanone Kaffeesatz', sans-serif;
padding-bottom: 20px;
border-radius: 5px;
`

const Question = ({ question, next, incScore, maxGlitz }) => {
    const [correctCount, setCorrectCount] = useState(0)
    const [showFeedback, setShowFeedback] = useState(false)
    const totalCorrect = question ? question.choices.filter(c => c.correct).length : 0
    const intervalRef = useRef(null)
    const taking = totalCorrect > correctCount
    soundtrack(taking ? Track.Taking : Track.NotTaking)

    const onChoiceSelected = (i, el) => {
        const correctGlitz = Math.floor(maxGlitz / totalCorrect)
        const incorrectGlitz = -Math.floor(maxGlitz / (question.choices.length - totalCorrect))
        if (question.choices[i].correct) {
            incScore(correctGlitz, el)
            setCorrectCount(correctCount + 1)
            if (correctCount + 1 === totalCorrect) {
                window.setTimeout(() => setShowFeedback(true), 3000)
            }
        } else {
            incScore(incorrectGlitz, el)
        }
    }

    useEffect(() => {
        setShowFeedback(false)
        setCorrectCount(0)
    }, [question])

    useEffect(() => {
        if (taking && !intervalRef.current) {
            intervalRef.current = window.setInterval(() => incScore(-7), 300)
        } else if (!taking && intervalRef.current) {
            window.clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [taking, incScore])

    return <>
        {!showFeedback && <>
            <QuestionText>{question.text}</QuestionText>
            {question.choices.map((c, i) => <Choice taking={taking} correct={c.correct} key={i}
                onSelect={(el) => { onChoiceSelected(i, el) }}>{c.content}</Choice>)}
        </>}
        {showFeedback && <FeedbackPanel>{question.feedback}
            <AnimatedButton label="next" onClick={next} /> </FeedbackPanel>}
    </>
}

export default memo(Question)