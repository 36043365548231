import { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import anime from 'animejs/lib/anime.es.js';
import { choiceSound } from '../services/soundfx'

const AnswerDiv = styled.div`
position: relative;
color: ${props => props.chosen ? "#421710" : "#ffe18e"};
background-color: ${props => props.chosen ? "#ffe18e" : "#421710"};
border-width: 1px;
border-color: #ffe18e;
border-radius: 8px;
border-style: solid;
text-align: center;
max-width: 400px;
width: 90%;
padding: 10px;
margin-top: 10px;
margin-bottom: 10px;
margin-right: auto;
margin-left: auto;
font-size: 150%;
padding:8px;
font-family: 'Yanone Kaffeesatz', sans-serif;
text-decoration: ${props => (props.chosen && props.correct === false) ? "line-through #421718 3px" : "none"}; 
:hover {
    transition: 0.6s;
    background-color: #ffe18e;
    color: #421710;
}
`

export default function Choice({ correct, onSelect, taking, children }) {
    const [chosen, setChosen] = useState(false)
    const ref = useRef()

    useLayoutEffect(() => {
        if (taking) {
            anime({
                targets: ref.current,
                rotateX: 0,
                duration: 2000
            })
        } else {
            anime({
                targets: ref.current,
                opacity: 0,
                duration: 2000
            })
        }
    }, [ref, taking])

    const pressedUp = () => {
        if (ref.current && taking) {
            anime({
                targets: ref.current,
                duration: 500,
                scale: 1
            })
        }
    }

    const pressedDown = () => {
        if (taking && !chosen && ref.current) {
            anime({
                targets: ref.current,
                duration: 300,
                scale: 0.9
            })
        }
    }

    const chosenAnim = () => {
        if (correct) {
            anime.timeline()
                .add({
                    targets: ref.current,
                    duration: 500,
                    scale: 1.2
                })
                .add({
                    targets: ref.current,
                    duration: 500,
                    scale: 1,
                    easing: 'spring(1, 80, 10, 0)'
                })
        } else {
            anime({
                targets: ref.current,
                duration: 500,
                scale: 1,
                easing: 'spring(1, 80, 10, 0)'
            })
        }
    }

    const clicked = () => {
        if (taking && !chosen) {
            choiceSound(correct)
            setChosen(true)
            chosenAnim()
            onSelect(ref.current)
        }
    }

    return <AnswerDiv chosen={chosen} correct={correct}
        style={{ transform: 'rotateX(90deg)' }}
        ref={ref} onMouseDown={pressedDown}
        onMouseOut={pressedUp} onMouseUp={clicked}>
        {children}
    </AnswerDiv>
}
