import React from 'react'

import styled from "styled-components";
import { ReactComponent as HomeIcon } from './titleletters/homeicon.svg'

const StyledHome = styled(HomeIcon)`
    position: absolute;
    z-index: 10;
    height: 25px;
    path {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:6px;
    }
    path.Two {
        opacity: ${props => props.clicked ? 0 : 1};
        stroke:none;
        fill:#ffe18e;
    }
    path.One {
        opacity: ${props => props.clicked ? 1 : 0};
        stroke:none;
        fill:#ffe18e;
    }
    :hover {
        path.Home {
            stroke-width: 8px;
        }
    }
`

export default function HomeButton({ onClick }) {
    const [clicked, setClicked] = React.useState(false)
    const onClickInner = () => {
        if (clicked) {
            onClick()
        } else {
            setClicked(true)
            setTimeout(() => setClicked(false), 1000)
        }
    }
    return (
        <StyledHome onClick={onClickInner} clicked={clicked} />
    )
}
