import { React, useLayoutEffect, useRef, createContext } from 'react'
import styled from "styled-components";

const StarryCanvas = styled.canvas`
  display: block;
  filter: blur(1px);
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const anim = (global, shapeNum, canvas, s) => {
    let width = canvas.width, height = canvas.height, speed = s;
    const rand = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    const Shape = function Shape(ctx, x, y) {
        this.ctx = ctx;
        this.init(x, y);
    }

    Shape.prototype.init = function (x, y) {
        this.x = x;
        this.y = y;
        this.r = rand(4, 16);
        this.ga = Math.random() * Math.random() * Math.random();
        this.v = {
            x: Math.random(),
            y: -1
        };
        this.maxLife = rand(20, 50);
        this.l = this.maxLife
        this.sl = this.l;
    };

    Shape.prototype.updateParams = function (X, Y) {
        this.l -= 1;
        if (this.l < 0) {
            const x = X < 1200 ? 1200 : X;
            this.init(X / 2 + x * (0.5 - Math.random()) * (0.5 - Math.random()), rand(0, Y));
            //this.init(X * (1 - Math.cos(Math.PI * Math.random())) / 2, rand(0, Y));
            //this.init(X * (0.5 + Math.pow(0.5 - Math.random(), 2) / 2), rand(0, Y));
        }
    };

    Shape.prototype.updatePosition = function () {
        this.y += Math.random();
    };

    Shape.prototype.draw = function () {
        const ctx = this.ctx;
        ctx.save();
        ctx.globalCompositeOperation = 'lighter';
        const lum = Math.sin(Math.PI * (this.maxLife - this.l) / this.maxLife)
        const rot = Math.sin(Math.PI * (0.2 + 0.4 * (this.maxLife - (this.l)) / this.maxLife))
        ctx.globalAlpha = this.ga * lum
        ctx.fillStyle = '#fff';
        ctx.beginPath();
        const r = this.r * rot / 2
        ctx.moveTo(this.x - r, this.y)
        ctx.lineTo(this.x + r, this.y)
        ctx.lineTo(this.x + r, this.y + this.r * 1.6)
        ctx.lineTo(this.x - r, this.y + this.r * 1.6)
        ctx.lineTo(this.x - r, this.y)
        ctx.fill();
        ctx.restore();
    };

    Shape.prototype.drawStar = function () {
        const ctx = this.ctx;
        ctx.save();
        ctx.globalCompositeOperation = 'lighter';
        ctx.globalAlpha = this.ga;
        ctx.fillStyle = '#fff';
        ctx.beginPath();
        const spikes = 5;
        const sharpness = 0.9;
        ctx.moveTo(this.x + this.r, this.y)
        const range = [...Array(spikes).keys()]
        range.forEach(i => {
            ctx.lineTo(this.x + this.r * Math.cos(Math.PI / spikes * (2 * i + 1)) * (1 - sharpness), this.y + this.r * Math.sin(Math.PI / spikes * (2 * i + 1)) * (1 - sharpness))
            ctx.lineTo(this.x + this.r * Math.cos(Math.PI * 2 / spikes * (i + 1)), this.y + this.r * Math.sin(Math.PI * 2 / spikes * (i + 1)));
        })
        ctx.fill();
        ctx.restore();
    };

    Shape.prototype.render = function (ctx) {
        this.updatePosition();
        this.updateParams(width, height);
        this.draw(ctx);
    };

    const initShape = () => {
        width = canvas.width
        height = canvas.height
        for (var i = 0; i < shapeNum; i++) {
            var s = new Shape(ctx, width * (Math.random() + Math.random()) / 2, rand(0, height));
            shapes.push(s);
        }
    }


    const ctx = canvas.getContext('2d')
    let shapes = [];
    initShape()

    const requestAnimFrame =
        global.requestAnimationFrame ||
        global.mozRequestAnimationFrame ||
        global.webkitRequestAnimationFrame ||
        global.msRequestAnimationFrame ||
        function (cb) {
            setTimeout(cb, 17);
        };


    const render = () => {
        ctx.clearRect(0, 0, width, height);
        if (width !== canvas.width || height !== canvas.height) {
            shapes = []
            initShape()
        }
        for (var i = 0; i < shapes.length; i++) {
            shapes[i].render(ctx, width, height);
        }
        if (speed > 0) {
            requestAnimFrame(render);
        }
    }

    const resize = (w, h) => {
        canvas.width = w;
        canvas.height = h;
        width = w;
        height = h;
    }

    const setSpeed = (s) => {
        const turnBackOn = (speed === 0 && s > 0)
        speed = s;
        if (turnBackOn) render();
    }

    return [render, resize, setSpeed]

}

const startAnim = (canvas, shapeNum) => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    var hasOffscreenSupport = !!canvas.transferControlToOffscreen;
    if (hasOffscreenSupport && !!URL.createObjectURL && Blob) {
        const offscreen = canvas.transferControlToOffscreen();
        const animCode = anim.toString()
        const code = [
            `let render = null, resize = null, setSpeed=null;`,
            `const anim = ${animCode};`,
            `onmessage = (e)=>{
                if (e.data.resize) {
                    resize(e.data.resize.width, e.data.resize.height); 
                } else if (e.data.offscreen) {
                    r = render || anim(this, e.data.shapeNum, e.data.offscreen, e.data.speed); 
                    render = r[0];
                    resize = r[1];
                    setSpeed = r[2];
                    render()
                } else {
                    setSpeed(e.data.speed)
                }
            }`
        ].join('')
        try {
            const worker = new Worker(URL.createObjectURL(new Blob([code])));
            worker.postMessage({ offscreen, shapeNum, speed: 1 }, [offscreen])
            console.log("using offline render")
            window.addEventListener('resize', () => {
                worker.postMessage({ resize: { width: window.innerWidth, height: window.innerHeight } })
            })
            return (s) => {
                worker.postMessage({ speed: s })
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('🎊 Could not load worker', e);
        }
    } else {
        console.log("using main thread render")
        const r = anim(window, shapeNum, canvas, 150)
        r[0]()
        window.addEventListener('resize', () => {
            r[1](window.innerWidth, window.innerHeight);
            r[0]()
        })
        return (s) => {
            r[2](s)
        }
    }

}

const StarryBackgroundContext = createContext({
    setSpeed: () => { }
})

const StarryBackground = ({ children }) => {

    /*
* File Name / glitteringSea.js
* Created Date / Aug 14, 2020
* Aurhor / Toshiya Marukubo
* Twitter / https://twitter.com/toshiyamarukubo
*/

    const canvasRef = useRef()
    const setSpeedRef = useRef(() => { })

    useLayoutEffect(() => {
        setSpeedRef.current = startAnim(canvasRef.current, 150)
        return () => {
            setSpeedRef.current(0);
        };
    }, [canvasRef])

    return (
        <StarryBackgroundContext.Provider value={{ setSpeed: setSpeedRef.current }}>
            <StarryCanvas ref={canvasRef} />
            {children}
        </StarryBackgroundContext.Provider>
    )
}

export { StarryBackgroundContext, StarryBackground }