import { React, useState, useRef, useLayoutEffect, useEffect, useContext, useCallback } from 'react'
import QuizContext from '../QuizContext'
import ScorePanel from './ScorePanel'
import styled, { keyframes } from "styled-components";
import confetti from 'canvas-confetti'
import anime from 'animejs/lib/anime.es.js';
import { StarryBackgroundContext } from './StarryBackground'
import { TwitterShareButton } from 'react-share'
import Question from './Question'
import AnimatedButton from './AnimatedButton'
import { ReactComponent as TwitterIcon } from './titleletters/iconmonstr-twitter-4.svg'
import FadeMeIn from './FadeMinIn'
import ReactGA from 'react-ga4'

const rotateAnim = keyframes`
  30% {transform: rotateY(90deg);}
  60% {transform: rotateY(-30deg);}
  70% {transform: rotateY(20deg);}
  80% {transform: rotateY(-14deg);}
  90% {transform: rotateY(5deg);}
  100% {transform: rotateY(0deg);}
`


const StyledTwitterIcon = styled(TwitterIcon)`
    width:64px;
    height: 64px;
    path {
        fill:#ffe18e;
        stroke:none;
    }
    animation: ${rotateAnim} 6s linear 1;
    animation-fill-mode: forwards;
`

const QuestionIndex = styled.div`
    border-radius: 5px;
    background-color: #ffe18e;
    color:#421710; 
    padding: 4px;
    margin: 4px;
`

const QuizPanel = styled.div`
display: flex;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
background: #421710;
padding:10px;
border-radius: 10px;
opacity: 0;
q {
    font-style: italic
}
pre {
    text-align: left;
    font-size: 80%;
    background: #421710;
    color: #ffe18e;
    font-family: 'Ubuntu Mono', monospace;
    border-style: dashed;
    border-width: 0.5px;
    padding: 5px;
}
img {
    max-width: 96%;
}`

const SummaryAndSocialPanel = styled.div`
padding-top: 140px;
display: flex;
align-items: center;
flex-direction: column;
font-size: 120%;
h3 {
    text-shadow: 0px 0px 4px #fff; 
    font-size: 200%;
}
b {
    font-size:150%
}
button {
    text-decoration: underline;
    :hover {
        font-weight: bold;
    }
}
`

const scaleUpAnim = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(2.6);}
  75% {transform: scale(1.5);}
  85% {transform: scale(2.3);}
  92% {transform: scale(1.8);}
  98% {transform: scale(2.1);}
  100% {transform: scale(2);}
`

const WellDoneDiv = styled.div`
    animation: ${scaleUpAnim} 1s linear 1;
    animation-fill-mode: forwards;
`

export const startScore = 10000
export const totalGlitzPerQuestion = 10000

export default function GlitzQuiz({ title, questions, reportUrl }) {
    const [score, setScore] = useState(startScore)
    const [questionIndex, setQuestionIndex] = useState(0)
    const question = questionIndex === questions.length ? null : questions[questionIndex]
    const quizPanelRef = useRef()
    const scorePanelRef = useRef()
    const maxImpossible = startScore + totalGlitzPerQuestion * questions.length
    const impossibleLabel = ["impossible", "unreachable", "unfathomable", "inconceivable", "unimaginable"][Math.floor(Math.random() * 5)]
    const twittext = `Just got ${score} glitzes out of an ${impossibleLabel} ${maxImpossible} on the ${title} #glitzquiz. And you? #${title.replace(/\s/g, '')}`
    let copyToClipboard = null;
    if (navigator.clipboard && navigator.clipboard.writeText) {
        copyToClipboard = () => navigator.clipboard.writeText(twittext)
    }

    const { setSpeed } = useContext(StarryBackgroundContext)

    setSpeed(question == null ? 1 : 0)

    const incScore = useCallback((num, el) => {
        setScore((prevScore) => (prevScore + num > 0 ? prevScore + num : 0))
        if (el) {
            const scorePanelBox = scorePanelRef.current ? scorePanelRef.current.getBoundingClientRect() : null;
            const scorex = scorePanelBox ? Math.floor(scorePanelBox.x + (scorePanelBox.width / 2)) : 0;
            const scorey = scorePanelBox ? Math.floor(scorePanelBox.y + (scorePanelBox.height / 2)) : 0;
            if (num > 0) {
                const elBox = el.getBoundingClientRect()
                confetti({
                    particleCount: 10,
                    spread: 180,
                    originRect: elBox,
                    gravity: 0,
                    startVelocity: 20,
                    stars: {
                        scale: 0.5,
                        hsl: [57, 80, 100],
                        velocity: 2
                    },
                    magnet: {
                        x: scorex,
                        y: scorey,
                        strength: 1
                    }
                })
            } else {
                confetti({
                    particleCount: 10,
                    spread: 180,
                    originRect: scorePanelBox,
                    gravity: 1,
                    startVelocity: 20,
                    stars: {
                        scale: 0.5,
                        hsl: [57, 80, 100],
                        velocity: 2
                    }
                })
                anime.timeline()
                    .add({
                        targets: scorePanelRef.current,
                        duration: 300,
                        scale: 1.2
                    })
                    .add({
                        targets: scorePanelRef.current,
                        duration: 500,
                        scale: 1,
                        easing: 'spring(1, 80, 10, 0)'
                    })
            };
        }
    }, [scorePanelRef])

    useLayoutEffect(() => {
        anime({
            targets: [quizPanelRef.current],
            opacity: 0.8,
            duration: 2000,
            easing: 'spring(1, 80, 10, 0)'
        })
    }, [quizPanelRef])


    useLayoutEffect(() => {
        if (!question) {
            anime({
                targets: [scorePanelRef.current],
                scale: 1.5,
                translateY: 100,
                duration: 1000,
            })
        }
    }, [question, scorePanelRef])

    useEffect(() => {
        if (!question && reportUrl) {
            fetch(reportUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ score, maxScore: maxImpossible })
            })
        }
    }, [score, maxImpossible, reportUrl, question])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', title })
    }, [title])

    const next = useCallback(() => {
        setQuestionIndex(questionIndex + 1)
    }, [questionIndex])

    const tryAgain = () => {
        anime({
            targets: [scorePanelRef.current],
            scale: 1,
            translateY: 0,
            duration: 1000,
            complete: () => {
                setQuestionIndex(0)
                setScore(startScore)
            }
        })

    }


    return <QuizContext.Provider value={{ score, incScore }}>
        <QuizPanel ref={quizPanelRef}>
            <ScorePanel ref={scorePanelRef} />
            {question && <><QuestionIndex>{questionIndex + 1}/{questions.length}</QuestionIndex>
                <Question question={question} next={next} incScore={incScore} maxGlitz={totalGlitzPerQuestion} /></>}
            {!question && <><WellDoneDiv>Bravo! You got</WellDoneDiv>
                <SummaryAndSocialPanel>
                    <h3>Glitzes!</h3>
                    <FadeMeIn delay={1000} duration={4000} initialDelay={2000}>
                        <p><b>That's something worth sharing :)</b></p>
                        <p style={{ textAlign: "center" }}><q>{twittext}</q></p>
                        <div style={{ display: "flex" }}>{copyToClipboard && <AnimatedButton onClick={copyToClipboard} label="copy" />} <TwitterShareButton url={"https://localhost:3000"} title={twittext}><StyledTwitterIcon /></TwitterShareButton></div>
                        <p>or you'd rather have even more glitzes?</p>
                        <AnimatedButton onClick={tryAgain} label="Try Again!" style={{ display: 'block' }} />
                    </FadeMeIn>
                </SummaryAndSocialPanel></>}
        </QuizPanel>
    </QuizContext.Provider >
}
