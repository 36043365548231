import { useState, useEffect } from 'react'
import { ReactComponent as StarControl } from './titleletters/star-sound.svg'
import styled from 'styled-components'
import { setVolume } from '../services/soundfx'

const SoundControlDiv = styled.span`
    polygon {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:10px;
    }
    path {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:10px;
    }
    path.vol50 {
        opacity: ${props => props.volume > 0 ? 1 : 0.1};
    }
    path.vol100 {
        opacity: ${props => props.volume > 1 ? 1 : 0.1};
    }
`

export default function SoundControl() {
    const [vol, setVol] = useState(2)
    useEffect(() => {
        setVolume(vol / 3)
    }, [vol])
    return (
        <SoundControlDiv onClick={() => setVol((vol + 1) % 3)} volume={vol}> <StarControl /></SoundControlDiv >
    )
}
