import { useEffect, useState, useRef, useCallback, forwardRef, useContext } from 'react'
import QuizContext from '../QuizContext'
import styled from 'styled-components'
import { ReactComponent as Star } from './titleletters/star.svg'
import { startScore } from './GlitzQuiz'

const minPerTick = 167;
const ScoreDiv = styled.div`
position: relative;
font-family: 'Bebas Neue', cursive;
font-size: 300%;
padding-top: 20px;
margin-bottom: 20px;
display: inline-block;
    svg {
        height: 40px;
        margin-left: 10px;
        margin-right: 10px;
    }
    polygon {
        fill: #f5ac1000;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:4px;
    }
`

const useForceRender = () => {
    const setC = useState(0)[1]
    return useCallback(() => setC((c) => c + 1), [setC])
}

const ScorePanel = forwardRef((props, ref) => {
    const { score } = useContext(QuizContext)
    const forceRender = useForceRender()
    const displayedRef = useRef(score)
    if (score === startScore) {
        displayedRef.current = score
    }
    if (score !== displayedRef.current) {
        let gap = Math.abs((score - displayedRef.current));
        if (gap > minPerTick) {
            gap = Math.max(Math.floor(gap / 23), minPerTick)
        }
        gap = displayedRef.current > score ? -gap : gap;
        displayedRef.current = displayedRef.current + gap
    }
    useEffect(() => {
        const interval = setInterval(() => forceRender(), 100)
        return () => {
            clearInterval(interval)
        }
    }, [forceRender])
    return (
        <ScoreDiv ref={ref}>
            <Star />{displayedRef.current}<Star />
        </ScoreDiv>
    )
})

export default ScorePanel;
