import { useLayoutEffect, createRef } from 'react'
import styled from 'styled-components'
import anime from 'animejs/lib/anime.es.js';

const ButtonDiv = styled.span`
position: relative;
border-color: #ffe18e;
border-width: 1px;
border-radius: 6px;
border-style: solid;
text-align: center;
padding: 10px;
margin-top: 10px;
margin-bottom: 10px;
margin-right: 10px;
margin-left: 10px;
font-family: 'Roboto', sans-serif;
transition: 0.3s;
:hover {
    transition: 0.6s;
    background-color: #ffe18e;
    color: #421710;
}
`

export default function AnimatedButton({ label, onClick, style }) {
    const ref = createRef();
    useLayoutEffect(() => {
        if (ref.current) {
            anime({
                targets: [ref.current],
                rotateX: 0,
                duration: 4000
            })
        }
    }, [ref])

    const pressedDown = () => {
        if (ref.current) {
            anime({
                targets: ref.current,
                duration: 1000,
                scale: 0.9
            })
        }
    }
    return <ButtonDiv ref={ref} onMouseDown={pressedDown} style={style}
        onClick={onClick}>
        {label}
    </ButtonDiv>
}

