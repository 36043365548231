import React from 'react'

import styled, { keyframes } from "styled-components";
import { ReactComponent as StarLoadingIcon } from './titleletters/star-loading.svg'

const StyledSpan = styled.div`
`

const rotateAnim = keyframes`
from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`


const StyledLoading = styled(StarLoadingIcon)`
    animation: ${rotateAnim} 6s linear ${props => props.broken ? 0 : 'infinite'};
    height: 160px;
    path {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:8px;
    }
    polyline {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:8px;
    }
    polygon {
        fill:none;
        stroke:#ffe18e;
        stroke-miterlimit:10;
        stroke-width:8px;
    }
    polygon.loading {
        opacity: ${props => props.broken ? 0 : 1};
    }
    polyline.broken {
        opacity: ${props => props.broken ? 1 : 0};
    }
    path.broken {
        opacity: ${props => props.broken ? 1 : 0};
    }
`

export default function StarLoading({ broken }) {
    return <>
        <StyledSpan><StyledLoading broken={broken} /></StyledSpan>
        {broken && <div>No glitz! Check the URL is correct and points to proper markdown</div>} </>
}
