import { Howl, Howler } from 'howler'


const backtrack = new Howl({ src: ["/assets/etheral.mp3"], loop: true, preload: true, volume: 1 })
const taking = new Howl({ src: ["/assets/etheral_taking.mp3"], loop: true, preload: true, volume: 1 })
const right = new Howl({ src: "assets/right_choice.mp3", preload: true })
const bad = new Howl({ src: "assets/bad_choice.mp3", preload: true })

let current = null;

const Track = {
    Taking: [taking, 0.7],
    NotTaking: [backtrack, 0.6]
}

const soundtrack = (track) => {
    if (current) {
        if (current === track) {
            return
        } else {
            current[0].fade(current[0].volume(), 0, 1000)
        }
    }
    current = track
    current[0].seek(0)
    current[0].fade(0, current[1], 1000)
    if (!current[0].playing()) current[0].play()
}

const choiceSound = (correct) => {
    (correct ? right : bad).play()
}

const setVolume = vol => {
    Howler.volume(vol * current[1])
}

export { Track, soundtrack, choiceSound, setVolume }